<template>
  <b-card-actions
    ref="cardA"
    :title="groupName"
    :action-collapse="false"
    action-refresh
    no-body
    :class="{ 'full-layout': !isAuthenticated }"
    @refresh="getGoogleAdsMockupList"
  >
    <b-card class="mb-2" no-body>
      <!-- !! Table Header -->
      <div class="table-filter">
        <HeroInputText
          id="filter"
          v-model="tableConfig.filter"
          class="filter-input"
          placeholder="Search"
          @input="debounce(getGoogleAdsMockupList, 500)()"
        />
        <HeroTablePerPage
          v-model="tableConfig.perPage"
          :options="tableConfig.perPageOptions"
          @input="debounce(getGoogleAdsMockupList)()"
        />
        <HeroButtonAction
          v-if="isAuthenticated"
          type="button"
          variant="primary"
          class="btn-add"
          :href="`/google-ads-mockup-generator?gId=${$route.params.groupId}`"
        >
          Add
        </HeroButtonAction>
      </div>

      <!-- !! Table -->
      <b-row class="my-1">
        <b-col cols="12">
          <b-table
            show-empty
            striped
            sticky-header="100%"
            :responsive="true"
            :items="items"
            :fields="tableConfig.fields"
            :per-page="0"
            :sort-by="tableConfig.sortBy"
            :sort-direction="tableConfig.sortDirection"
            :no-sort-reset="true"
            :no-local-sorting="true"
            @sort-changed="onTableSortChanged"
          >
            <template #cell(name)="{ item, field }">
              {{ getCurrentRevisionByField(item, field) }}
            </template>
            <template #cell(created_by_name)="{ item, field }">
              {{ getCurrentRevisionByField(item, field) }}
            </template>
            <template #cell(revisions)="{ item }">
              <HeroVueSelect
                :id="`google-ads-mockup-${item.id}`"
                v-model="item.currentRevision"
                :options="item.revisions"
                :clearable="false"
                append-to-body
                :calculate-position="calculateSelectPosition"
                class="mb-0"
              />
            </template>
            <template #cell(action)="{ item, index }">
              <div class="d-flex align-items-center col-gap-3">
                <router-link
                  :to="`/google-ads-mockup-generator/${item.unique_id}?gId=${item.google_ads_mockup_group_id}&createdAt=${item.currentRevision}`"
                >
                  <feather-icon
                    v-b-tooltip="'Edit'"
                    icon="Edit2Icon"
                    size="20"
                    class="cursor-pointer"
                    style="color: #6e6b7b"
                  />
                </router-link>
                <feather-icon
                  v-if="isAuthenticated"
                  v-b-tooltip="`${isCopied ? 'Copied' : 'Copy'} link to clipboard`"
                  icon="GlobeIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="copyLink(item)"
                />
                <router-link
                  v-if="isAuthenticated && item.revisions[0].value !== item.currentRevision"
                  :to="`/google-ads-mockup-generator/${item.unique_id}?gId=${item.google_ads_mockup_group_id}&createdAt=${item.currentRevision}&mode=duplicating`"
                  target="_blank"
                >
                  <feather-icon
                    v-b-tooltip="'Duplicate'"
                    icon="CopyIcon"
                    size="20"
                    class="cursor-pointer"
                    style="color: #6e6b7b"
                  />
                </router-link>

                <b-spinner
                  v-if="item.isFormExporting"
                  v-b-tooltip="'Exporting'"
                  small
                />
                <feather-icon
                  v-if="isAuthenticated && !item.isFormExporting"
                  v-b-tooltip="'Export'"
                  icon="DownloadIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="exportGoogleAdsMockup(item, index)"
                />
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <!-- !! Table Footer -->
      <div class="mx-1 mb-1">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <HeroTablePagination
              v-model="tableConfig.currentPage"
              :per-page="tableConfig.perPage"
              :total-rows="tableConfig.totalRows"
              @input="debounce(getGoogleAdsMockupList)()"
            />
          </b-col>
          <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
            <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-card-actions>
</template>

<script>
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import DebounceMixin from '@/mixins/debounce'
import moment from 'moment/moment'
import axiosInstance from '@/libs/axiosInstance'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import { BSpinner } from 'bootstrap-vue'

export default {
  name: 'GoogleAdsMockup',
  components: {
    BSpinner,
    HeroVueSelect,
    HeroTablePagination,
    BCardActions,
    HeroTablePerPage,
    HeroButtonAction,
    HeroTableStatus,
    HeroInputText,
  },
  mixins: [DebounceMixin],
  data() {
    return {
      groupName: '',
      isCopied: false,
      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Name',
            key: 'name',
            sortable: true,
          },
          {
            label: 'Editor Name',
            key: 'created_by_name',
            sortable: true,
            thStyle: { minWidth: '300px' },
          },
          {
            label: 'Revision',
            key: 'revisions',
            sortable: false,
            thStyle: {
              minWidth: '270px',
              width: '270px',
            },
          },
          {
            label: 'Action',
            key: 'action',
            sortable: false,
          },
        ],
      },
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['heroAiAuthentications/isAuthenticated']
    },
  },
  mounted() {
    this.getGoogleAdsMockupList()
  },
  methods: {
    async copyLink(item) {
      this.isCopied = true
      const link = `${window.location.origin}/google-ads-mockup-generator/${item.unique_id}?gId=${item.google_ads_mockup_group_id}&createdAt=${item.currentRevision}`
      await navigator.clipboard.writeText(link)
      setTimeout(() => {
        this.isCopied = false
      }, 500)
    },

    async exportGoogleAdsMockup(item, index) {
      try {
        this.items[index].isFormExporting = true

        const url = `/google-ads-mockups/groups/${item.google_ads_mockup_group_id}/mockups/${item.unique_id}/export`
        const response = await axiosInstance.get(url, { params: { created_at: item.currentRevision } })

        window.open(response.data.data, '_blank')
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.items[index].isFormExporting = false
      }
    },

    onTableSortChanged(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.getGoogleAdsMockupList()
    },

    async getGoogleAdsMockupList() {
      try {
        this.$refs.cardA.showLoading = true

        const {
          perPage, currentPage, filter, sortBy, sortDirection,
        } = this.tableConfig

        const url = `google-ads-mockups/groups/${this.$route.params.groupId}/client?perPage=${perPage}&page=${currentPage}&q=${filter}&orderByColumn=${sortBy}&orderByDirection=${sortDirection}`
        const response = await axiosInstance.get(url)

        this.groupName = response.data.data.group_name || ''

        this.tableConfig.currentPage = +response.data.data.current_page
        this.tableConfig.perPage = +response.data.data.per_page
        this.tableConfig.totalRows = +response.data.data.total
        this.items = response.data.data.data.map(item => ({
          ...item,
          currentRevision: item.revisions.length ? item.revisions[0].created_at : '',
          revisions: item.revisions.map(revision => ({
            ...revision,
            text: revision.created_at,
            value: revision.created_at,
          })),
          isFormExporting: false,
        }))
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.data.status === 'error') {
          if (this.isAuthenticated) {
            this.$router.replace('/google-ads-mockup-group')
          }
        }
      } finally {
        this.$refs.cardA.showLoading = false
      }
    },

    getCurrentRevisionByField(item, field) {
      return item.revisions.find(revision => revision.created_at === item.currentRevision)[field.key] || ''
    },

    calculateSelectPosition(dropdownList, component, { width, top, left }) {
      // eslint-disable-next-line no-param-reassign
      dropdownList.style.top = `${+top.replace('px', '') + 12}px`
      // eslint-disable-next-line no-param-reassign
      dropdownList.style.left = `${+left.replace('px', '') + 6}px`
      // eslint-disable-next-line no-param-reassign
      dropdownList.style.width = width
    },
  },
}
</script>

<style scoped lang="scss">
.full-layout {
  padding: 2rem 2rem 0 2rem;
}
.table-filter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  padding-left: 1rem;
  padding-right: 1rem;
  .filter-input {
    flex: 0 0 100%;
    margin-bottom: 0;
    @media(min-width: 576px) {
      flex: 0 0 50%;
    }
    @media(min-width: 768px) {
      flex: 0 0 30%;
    }
  }
  .btn-add {
    width: auto;
    margin-left: auto;
  }
}
</style>

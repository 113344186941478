import { render, staticRenderFns } from "./GoogleAdsMockupList.vue?vue&type=template&id=f389f600&scoped=true&"
import script from "./GoogleAdsMockupList.vue?vue&type=script&lang=js&"
export * from "./GoogleAdsMockupList.vue?vue&type=script&lang=js&"
import style0 from "./GoogleAdsMockupList.vue?vue&type=style&index=0&id=f389f600&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f389f600",
  null
  
)

export default component.exports